.Tabs-container {
    margin: 60px 0px 50px 0px;
    background-color:#02ade9;
    padding: 20px 0px;
}

.ButtonG {
    border: none;
    color: white;
    font-weight: 600;
    font-family: var(---Font02);
    letter-spacing: 1.5px;
}

.btn:hover{
    background-color: transparent !important;
}