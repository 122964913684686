@charset "utf-8";

/* Theme Color */
.order-box ul li span,
.feature-tabs .tabs-content ul li:before,
.testimonial-block .designation,
.news-block .content-box .info li a:hover,
.news-block .content-box .read-more,
.mixitup-gallery .filters li:hover,
.shipping-block .inner-box h4,
.cart-table tbody tr .remove-btn:hover,
.checkout-form .form-group .field-label span,
.checkout-page .default-links li a,
.checkout-page .default-links li .fa,
.list-style-one li:before,
.updated-work-section .view-all,
.updated-work-section .owl-next:hover,
.updated-work-section .owl-prev:hover,
.list-style-three li:before,
.footer-style-two .about-widget .social-icon li a:hover,
.updated-news-block .inner-box:hover .image .overlay-box h3 a,
.updated-feature-block-two .inner-block .content h3 a:hover,
.updated-testimonial-block .inner-box .author-info .info-inner .rating .fa,
.updated-services-section .services-tabs .tab-btns .title,
.main-nav-outer .main-nav-box .main-nav .navigation > li:hover > a,
.main-nav-outer .main-nav-box .main-nav .navigation > li.current > a,
.main-nav-outer .main-nav-box .main-nav .navigation > li > ul > li:hover > a,
.main-nav-outer .main-nav-box .main-nav .navigation > li > ul > li.current > a,
.about-us .content-column .list-style-one li:before,
.product-info-tabs .prod-tabs .add-review .rating .rate-box:hover,
.product-info-tabs .prod-tabs .add-review .rating .rate-box:focus,
.product-info-tabs .prod-tabs .add-review .rating .rate-box:active,
.product-info-tabs .prod-tabs .reviews-container .rating,
.product-details .basic-details .rating,
.sidebar .related-posts .rating span,
.sidebar .related-posts .post a:hover,
.shop-comment-form .rating-box .rating a:hover,
.shop-item .lower-content .rating,
.shop-item .lower-content h3 a:hover,
.sort-by .ui-button .ui-icon,
.sidebar-page-container .comments-area .comment .comment-reply,
.latest-news .post:hover h3 a,
.category-list li a:hover,
.category-list li a:before,
.sidebar .search-box .form-group button,
.news-widget .post .date,
.main-footer .services-list li a:hover:before,
.main-footer .services-list li a:hover,
.main-footer .about-widget .social-icon li a:hover,
.call-to-action.style-two .btn-box .theme-btn:hover,
.call-to-action .title-box h3 span,
.map-data h6,
.contact-info ul li:hover .icon,
.contact-info ul li p > span,
.contact-info ul li strong,
.contact-info ul li a:hover,
.pricing-table .price span,
.pricing-table .title h3,
.styled-pagination li a:hover,
.styled-pagination li a.active,
.post-share-options .next-prev a:hover,
.post-share-options .social-links li a:hover,
.post-share-options h4,
.news-block-two .content .read-more:hover,
.news-block-two .content h3 a:hover,
.news-block-two .info-box li a:hover,
.testimonial-block-three .rating span,
.testimonial-carousel-two .owl-prev:hover,
.testimonial-carousel-two .owl-next:hover,
.testimonial-carousel-two .owl-nav:before,
.testimonial-block-two .info-box .rating i,
.team-block .info-box .name a:hover,
.news-block .content-box .read-more
.news-block .content-box .info li a:hover,
.news-block .content-box h3 a:hover,
.subscribe-section .call-btn:hover,
.accordion-box .block .acc-btn.active,
.accordion-box .block .acc-btn .icon,
.project-info .social-icons a:hover,
.project-info .social-icons .follow,
.project-info .info strong,
.project-info .info a:hover,
.project-detail .check-list li:before,
.project-tab .tab-btns .tab-btn.active-btn,
.project-tab .tab-btns .tab-btn:hover,
.gallery-item .icon-box h3 a:hover,
.mixitup-gallery .filters li.active,
.mixitup-gallery .filters li:hover
.feature-tabs .tabs-content ul li:before,
.feature-tabs .tabs-content .title,
.brochure-box .link-box a span,
.service-detail ul li:before,
.service-detail blockquote cite,
.services-list .service:hover h3 a,
.service-block-two .read-more,
.service-block-two h3 a:hover,
.service-block-two:hover .icon-box,
.features-carousel .owl-prev:hover,
.features-carousel .owl-next:hover,
.feature-block .lower-content h3 a:hover,
.fun-fact-two .count-box .counter-title h3,
.fun-fact-two .count-box .count-text,
.about-us .image-column .column .read-more,
.about-us .image-column .column h3 a:hover,
.about-us .content-column .primary-text,
.page-title .bread-crumb li a:hover,
.page-title .bread-crumb li,
.sec-title .separator,
.header-style-four .main-menu .navigation > li:hover > a,
.header-style-four .main-menu .navigation > li.current > a,
.header-style-four .header-top .top-right p i,
.header-style-four .header-top .top-left ul li i,
.header-style-three .header-top .top-right p i,
.header-style-three .header-top .top-left ul li i,
.header-style-two .header-upper .main-menu .navigation > li.current > a,
.header-style-two .header-upper .main-menu .navigation > li:hover > a,
.main-header .search-panel .search-btn:hover,
.main-header .search-box-btn:hover,
.main-menu .navigation > li.current-menu-item > a,
.main-header .info-box li a:hover,
.btn-style-five:hover,
.btn-style-four,
.btn-style-one:hover,
.list-style-two li .icon,
.list-style-one li:before,
.theme_color,
a,
.search-popup .recent-searches li a:hover,
.team-detail .social-icon-three li a,
.faq-section .prod-tabs .tab-btns .tab-btn.active-btn span, .faq-section .prod-tabs .tab-btns .tab-btn:hover span {
	color: #02ade9;
}

/*Background Color*/
.news-block-two .date-and-like .like,
.ui-state-active, .ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active, a.ui-button:active,
.ui-button:active, .ui-button.ui-state-active:hover,
.order-box .title-box,
.sidebar-widget .range-slider-one .ui-state-default,
.sidebar-widget .range-slider-one .ui-widget-content .ui-state-default,
.shop-item .overlay-box .cart-btn:hover,
.tag-list li a:hover,
.message-icon-box,
.updated-clients-section,
.video-box .overlay-box span,
.updated-price-block .inner-box:hover::before,
.updated-pricing-table .inner-box:hover::before,
.updated-contact-section .inner-container,
.updated-feature-block .block-inner .icon,
.footer-style-two .gallery-widget .image a:before,
.main-header-bar .nav-toggler .toggler-btn .bar,
.updated-team-section .owl-dots .owl-dot:hover,
.updated-team-section .owl-dots .owl-dot.active,
.main-content-box .fixed-top-bar .outer-box .cart-btn .count,
.updated-services-section .services-tabs .tabs-content .content-column h2:before,
.fun-fact-section-two .count-box .counter-title h5:before,
.updated-testimonial-section .owl-dots .owl-dot.active,
.updated-testimonial-section .owl-dots .owl-dot:hover,
.updated-news-block .inner-box .image .overlay-box,
.main-footer .gallery-widget .image a:before,
.contact-info-section,
.contact-form .form-group input[type="submit"],
.contact-form button,
.check-box input[type="checkbox"]:checked + label:before,
.vehicle-detail .range-slider-one .ui-state-default:before,
.team-block-two .inner-box:hover .caption-box,
.team-block-two .social-links li a:hover,
.styled-pagination li a.next:hover,
.styled-pagination li a.prev:hover,
.testimonial-block-two .image-box:after,
.testimonial-carousel-three .owl-dots .owl-dot.active,
.testimonial-carousel-three .owl-dots .owl-dot:hover,
.testimonial-carousel .owl-prev:hover,
.testimonial-carousel .owl-next:hover,
.testimonial-block .icon,
.team-block .social-icon,
.news-carousel .owl-prev:hover,
.news-carousel .owl-next:hover,
.news-block .label .likes,
.subscribe-section .inner-container,
.video-and-faqs .video-column .image .link span,
.gallery-item .icon-box .link:hover .icon,
.feature-tabs .tab-btn.active-btn,
.service-cat-list li.active a,
.service-cat-list li a:hover,
.service-detail .video-column .image .link span,
.services-list .service:hover .icon-box,
.feature-block .lower-content .read-more:hover,
.feature-block .image-box .price,
.fun-fact-two .count-box:nth-child(4n + 4) .inner-box,
.fun-fact-two .count-box:nth-child(4n + 3) .count-text:before,
.fun-fact-two .count-box:nth-child(4n + 2) .count-text:before,
.fun-fact-section .count-box .counter-title h5,
.main-header .cart-btn .count,
.header-style-two .header-top:before,
.main-header .sticky-header .main-menu .navigation > li:hover > a,
.main-header .sticky-header .main-menu .navigation > li.current > a,
.main-header .sticky-header .main-menu .navigation > li.current-menu-item > a,
.main-menu .navigation > li > ul > li  > ul > li > a:hover,
.main-menu .navigation > li > ul > li:hover > a,
.main-header .header-top,
.scroll-to-top:hover,
.btn-style-five,
.btn-style-four:hover,
.btn-style-two:hover,
.btn-style-one,
.dez-quik-search,
.search-popup .search-form fieldset input[type="submit"],
.main-menu .navigation > li.current > a,
.team-single-section .team-detail .content-column .inner-box .title:after,
.skills-box h3:before,
.updated-renovation-section .upper-section .content-column .inner-column .left-line::before,
.updated-renovation-section .upper-section .content-column .inner-column::after,
.faq-section .prod-tabs .tab-btns .tab-btn:hover:before, .faq-section .prod-tabs .tab-btns .tab-btn.active-btn:before,
.main-header-bar .social-links:before,
.updated-work-block .inner-block .image-column .image .popop-boxed,
.updated-pricing-table.active .inner-box::before {
	background-color: #02ade9;
}

.progress-levels .progress-box .bar .bar-fill,
.main-menu .navigation > li .mega-menu-bar .column > ul > li > a:hover {
	background: #02ade9;
}

.service-block .caption-box{
	background-color: rgba(28,99,184, .80);
}

.fun-fact-section-two .count-box:hover .counter-title h5{
	background-color: rgba(28,99,184, 0.50);
}

/*Border Color*/
.service-form .form-group input:focus,
.service-form .form-group textarea:focus,
.ui-state-active, .ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active, a.ui-button:active,
.ui-button:active, .ui-button.ui-state-active:hover,
.pricing-tabs .tab-btns,
.search-popup .recent-searches li a:hover,
.updated-news-block-two .inner-box .image .overlay-box:before,
.updated-feature-block-two .inner-block:hover::before,
.updated-renovation-section .upper-section .content-column .inner-column:before,
.updated-feature-block-two .inner-block:hover .content:before, .updated-feature-block-two .inner-block:hover,
.login-form .form-group input[type="text"]:focus,
.login-form .form-group input[type="password"]:focus,
.login-form .form-group input[type="tel"]:focus,
.login-form .form-group input[type="email"]:focus,
.login-form .form-group select:focus,
.login-form .form-group textarea:focus,
.shipping-form .form-group input[type="text"]:focus,
.shipping-form .form-group input[type="password"]:focus,
.shipping-form .form-group input[type="tel"]:focus,
.shipping-form .form-group input[type="email"]:focus,
.shipping-form .form-group select:focus,
.shipping-form .form-group textarea:focus,
.checkout-form input:focus,
.checkout-form select:focus,
.checkout-form textarea:focus,
.product-info-tabs .prod-tabs .add-review .form-group textarea:focus,
.product-info-tabs .prod-tabs .add-review .form-group input:focus,
.product-info-tabs .prod-tabs .add-review .form-group select:focus,
.shop-comment-form .form-group input[type="text"]:focus,
.shop-comment-form .form-group input[type="password"]:focus,
.shop-comment-form .form-group input[type="tel"]:focus,
.shop-comment-form .form-group input[type="email"]:focus,
.shop-comment-form .form-group select:focus,
.shop-comment-form .form-group textarea:focus,
.comment-form .form-group input:focus,
.comment-form .form-group textarea:focus,
.tag-list li a:hover,
.sidebar .search-box .form-group input:focus,
.contact-info ul li:hover .icon,
.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus,
.check-box input[type="checkbox"]:checked + label:before,
.check-box input[type="checkbox"]:checked + label,
.appointment-form .form-group input:focus,
.appointment-form .form-group select:focus,
.appointment-form .form-group textarea:focus,
.pricing-table.tagged .inner-box,
.pricing-table .inner-box:hover,
.styled-pagination li a.next:hover,
.styled-pagination li a.prev:hover,
.styled-pagination li a:hover,
.styled-pagination li a.active,
.blog-detail blockquote,
.testimonial-carousel .owl-prev:hover,
.testimonial-carousel .owl-next:hover,
.testimonial-block.even:before,
.testimonial-block .thumb,
.testimonial-block:before,
.team-block .info-box,
.team-block .image-box:before,
.gallery-item .icon-box .link:hover .icon,
.feature-tabs .tab-btn.active-btn,
.services-list .service:hover .icon-box,
.fun-fact-section .count-box .inner-box,
.main-header .search-panel input:focus,
.main-header .search-panel select:focus,
.main-menu .navigation > li > ul > li > ul,
.main-menu .navigation > li > ul,
.btn-style-five:hover,
.btn-style-four:hover{
	border-color: #02ade9;
}

.main-header  .dropdown-menu,
.faq-section .prod-tabs .tab-btns {
	border-top-color: #02ade9;
}

.testimonial-block.even .icon:after,
.testimonial-block .icon:after,
.fun-fact-section .count-box .counter-title h5:before{
	border-bottom-color: #02ade9;
}

.main-header .search-box-btn {
   border: 1px dashed #02ade9;
}

.main-header-bar .social-links li a {
	border: 1px solid #02ade9;
}

@media only screen and (max-width: 767px){

	.main-menu .navbar-header .navbar-toggle{
		border-color:#02ade9;
	}

	.main-menu .navbar-header .navbar-toggle,
	.main-menu .navbar-collapse > .navigation,
	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul,
	.main-menu .navbar-collapse > .navigation > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > a:hover,
	.main-menu .navbar-collapse > .navigation > li > a:active,
	.main-menu .navbar-collapse > .navigation > li > a:focus,
	.main-menu .navbar-collapse > .navigation > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li.current > a,
	.main-menu .navbar-collapse > .navigation > li.current-menu-item > a,
	.header-style-two.inner-header .main-menu .navigation > li > a{
		background-color:#02ade9;
	}

}
